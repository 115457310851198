import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ;

export default {

  getPromoList(){ //console.log(params);
    return axios.get(url + '/opay-api/promo/list').then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getActivePromoList(){ //console.log(params);
    return axios.get(url + '/opay-api/promo/active/list').then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getMerchantList(params){ //console.log(params);
    return axios.post(url + '/customer-care-portal/merchant/list/all/'+params.page+'/'+ params.size).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  createNewPromo(params){ //console.log(params);
    return axios.post(url + '/opay-api/promo/save',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  retrivePromo(promoCode){
    return axios.post(url + '/opay-api/promo/get/'+promoCode).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  updatePromo(params){
    return axios.post(url + '/opay-api/promo/update',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  removePromo(promoCode){
    return axios.get(url + '/opay-api/promo/remove/'+promoCode).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  uploadImagePromo(promoCode,formData){
    return axios.post(url + '/opay-api/promo/resource-upload/'+promoCode, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  downloadImagePromo(key){
    return axios.get(url + '/opay-api/promo/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response });
  },

  getReferralList(params){ //console.log(params);
    return axios.get(url + '/opay-api/promo/referral/list/'+params.fromDate+'/'+params.toDate,{
      headers: {
        'Access-Control-Allow-Origin' : '*'
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getDefaultImageList(){
    return axios.get(url + '/customer-care-portal/config/default-image-list').then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  uploadDefaultImagePromo(formData){
    return axios.post(url + '/opay-api/promo/default-resource-upload', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getpromoByType(promoType){
    return axios.get(url + '/opay-api/promo/get-promo/'+promoType).then(response =>response.data)
    .catch((error) => { return error.response });
  }

};
