<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="8">
                    <h4>Referral</h4>
              </CCol>
              <CCol md="4">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                      
              </CCol>
             
          </CRow>
        </CCardHeader><br/>
        <CCardBody>     

         <CRow>
              <CCol md="4">                   
                   <CInput
                      label="From Date"
                      type="date"
                      horizontal
                      v-model="fromDate" 
                      @input="startDateOnChange()" 
                    />            
              </CCol>
              <CCol md="4">                   
                   <CInput
                      label="To Date"
                      type="date"
                      horizontal
                      v-model="toDate" 
                      @input="toDateOnChange()" 
                    />            
              </CCol>              
          </CRow>


        <div class="table-responsive table">
        <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">

        <template v-slot:cell(userIdentify.userId)="{ item }"> 
              {{ item.userIdentify.userId | IdSubstr }}
        </template>

        <template v-slot:cell(registeredDate)="{ item }"> 
              {{ item.registeredDate | dateWithTime }}
        </template>

        <template v-slot:cell(userStatus)="{ item }"> 
              {{ item.userStatus | lower }}
        </template>

        <template v-slot:cell(actions)="{ item }">
          <b-dropdown variant="info" text="Actions" size="md">
            <router-link :to='"/eWallet/details/" + item.userIdentify.userId' tag="b-dropdown-item" > Detail </router-link>
          </b-dropdown>
        </template>

      </b-table>
      </div>

      <div>
        <b-row>
          <b-col sm="3">
             <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
          </b-col>       
          <b-col sm="9">
             <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
             </b-form-select>
          </b-col>
        </b-row>      
      </div>



          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import PromoService from '@/api/PromoService.js';
import moment from 'moment';

 export default {
    name: "users",
    data() { var date = new Date();
      return {
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10),  
        items:[],             
        fields: [   
          {
            name : 'code',
            key: 'code',
            label : 'Referral Code',
            sortable: true
          },  
          {
            key: 'newUserId',
            label : 'New User ID',
            sortable: true
          },                     
          {
            key: 'newUserPhone',
            label : 'New user Phone',
            sortable: true
          },         
          {
            key: 'referredById',
            label : 'Referred ID',
            sortable: true
          },           
          {
            key: 'referredByName',
            label : 'Referred Name',
            sortable: false
          },         
          {
            key: 'referredByNumber',
            label : 'Referred Number',
            sortable: true
          }, 
          {
            key: 'registeredDate',
            label : 'Registered Date',
            sortable: true
          }          
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


      },

    created () { 
           
       let params = {}; 
       params['fromDate'] = this.fromDate;
       params['toDate']   = this.toDate;
       this.getReferralList(params);     
    },
    methods: { 

      startDateOnChange(){

         let params = {}; 
         params['fromDate'] = this.fromDate;
         params['toDate']   = this.toDate;
         this.getReferralList(params);     

      },

      toDateOnChange(){

         let params = {}; 
         params['fromDate'] = this.fromDate;
         params['toDate']   = this.toDate;
         this.getReferralList(params);     


      },

      getReferralList: function(params) {
        PromoService.getReferralList(params).then(resp => {         
          this.items = resp.payload;  //console.log(resp);

        }, error => {
          this.loading = false;
        });
      },          

      fetchUsers: function() {
        AuthService.getUserList({page:0, size:10000}).then(response => {         
        this.items = response.payload;  //console.log(response);
        }, error => {
          this.loading = false;
        });
      }
    },
    mounted() { 
      
    },   
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>